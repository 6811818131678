import * as dompack from 'dompack';
import '@mod-system/js/wh/integration';
import 'dompack/browserfix/reset.css';

import './shared/forms/forms';
import './shared/rtd/rtd';

import './corporate.scss';

// import './web/js/core.min';
// import './web/js/script.js';

import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
openLinksInNewWindow();

// components
import './embeddedobjects/embeddedobjects';
// import '@mod-webwerf_sites/pages/photoalbum/photoalbum';

// pages
import './pages/home/home';
import './pages/tours/tours';

import $ from 'jquery';

let touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
if (!touchsupport){ // browser doesn't support touch
  document.documentElement.className += ' non-touch';
}

dompack.onDomReady(() => {
  let $tourInput = $('.wh-form__fieldgroup.tourname input');
  if ($tourInput.val() !== '')
    $tourInput.attr('readonly', 'readonly');
});
