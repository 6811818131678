import './toursfromcontext.scss';

import * as dompack from 'dompack';

import '../../web/css/map.css';

// vendor
const jQuery = window.jQuery = require('jquery');
require('../../web/js/jquery.cssmap.js');

dompack.onDomReady(() => {
  let $container = $('.emb-toursfromcontext');

  // set height of .emb-toursfromcontext__addresses to the biggest height
  // let addressesHeight = 0;
  // jQuery('.emb-toursfromcontext__addresses li').each(function() {
  //   let height = jQuery(this).height();
  //   if (height > addressesHeight)
  //     addressesHeight = height;
  // });

  // jQuery('.emb-toursfromcontext__addresses').height(addressesHeight);

  // setup country click (show and scroll to locations)
  jQuery('.emb-toursfromcontext__countrylink').click(function(evt) {
    evt.preventDefault();
    if (getViewPortWidth() > 1024) {
      //desktop
      const idParts = jQuery(this).data('countryid').split('-');
      const id = parseInt(idParts[1], 10);

      const $locationsContainer = jQuery('.emb-toursfromcontext__locations-container[data-countryid="' + id + '"]');

      disableAllLocationContainers();
      $locationsContainer.addClass('enabled');

      jQuery('.emb-toursfromcontext__countrylink').removeClass('selected');
      jQuery(this).addClass('selected');

      // scroll to addresses
      jQuery('html, body').animate({
        scrollTop: jQuery(".js-contact-locations").offset().top - 75 /* header height */
      }, 750);
    } else {
      //mobile
      jQuery(this).parent().toggleClass('enabled');
    }
  });

  // setup world map
  jQuery('#map-continents').cssMap({
    size: 960,
    tooltips: 'floating',
    clicksLimit: 1,     // Integer: limited number of clicks; 0 - unlimited clicks;
    agentsListId: '#addresses',
    // optional: show and hide agent/address on hover;
    agentsListOnHover: false,
    onClick: function(listItem){
      let continent = $(listItem).data('continent');
      let $enable = $(`.emb-toursfromcontext__continent[data-continent="${continent}"]`);
      if ($enable) {
        $('.emb-toursfromcontext__continent').hide();
        $enable.show();
      }
    },
    onSecondClick: function(listItem){
      $('.emb-toursfromcontext__continent').hide();
    },
  });

  jQuery('.emb-toursfromcontext__addresses h1').click(function(evt) {
    evt.preventDefault();
    jQuery(this).parent().toggleClass('enabled');
  });

  jQuery('.js-emb-toursfromcontext-container').css('visibility', 'visible');
});

function disableAllLocationContainers() {
  jQuery('.emb-toursfromcontext__locations-container').removeClass('enabled');
}

function getViewPortWidth() {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}
